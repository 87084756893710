/* eslint-disable complexity */
import Vue from 'vue';

import type { AuthorRequest } from '@/types/AuthorRequest';

export type RequestAction = {
    name: string;
    icon?: string;
    color?: string;
    iconText?: string;
    action: string;
    link?: boolean;
    route?: boolean;
    callback: (context: Vue, request?: AuthorRequest) => void | string;
};

const ASSIGNMENT_STATUS = {
    DRAFT: 0,
    PROGRESS: 1,
    EDITORIAL: 2,
    SENT: 3,
    FLAGGED: 4
};

const actions: Record<
    'comments' | 'review' | 'edit' | 'remove' | 'edit_article' | 'settings',
    RequestAction
> = {
    comments: {
        name: 'Comments',
        icon: 'comment',
        color: 'turquoise',
        action: 'comments',
        callback(context, request) {
            context.$emit('show-comments', request?.latest_assignment?.id);
        }
    },
    review: {
        name: 'Request Overview',
        icon: 'eye',
        color: 'primary',
        action: 'review',
        route: true,
        callback(_context, request) {
            return request ? `/author-requests/review/${request?.id}` : '';
        }
    },
    edit: {
        name: 'Edit',
        icon: 'edit',
        color: 'primary',
        action: 'edit',
        route: true,
        callback(_context, request) {
            return request ? `/author-requests/create/${request?.id}` : '';
        }
    },
    remove: {
        name: 'Delete',
        icon: 'trash-alt',
        color: 'error',
        action: 'delete',
        callback(context) {
            context.$store.dispatch('modal/open', 'author-request-delete');
        }
    },
    edit_article: {
        name: 'Edit',
        icon: 'edit',
        action: 'edit',
        route: true,
        callback(_context, request) {
            // don't define target module in the link, use routes.ts
            return request
                ? `/announcements/review/${request?.latest_assignment?.announcement_id}`
                : '';
        }
    },
    settings: {
        name: 'Request Settings',
        icon: 'gear',
        color: 'secondary',
        action: 'settings',
        route: true,
        callback(_context, request) {
            return request ? `/author-requests/settings/${request?.id}` : '';
        }
    }
};

export default class AuthorRequestActionsFactory {
    request: Partial<AuthorRequest>;
    isEditor = false;
    isAuthor = false;

    constructor(
        request: Partial<AuthorRequest>,
        isEditor = false,
        isAuthor = false
    ) {
        this.request = request;
        this.isEditor = isEditor;
        this.isAuthor = isAuthor;
    }

    get editable() {
        return (
            !this.request.latest_assignment ||
            (this.request.latest_assignment.status ===
                ASSIGNMENT_STATUS.DRAFT &&
                !this.rejected)
        );
    }

    get paused() {
        return this.request.paused_at;
    }

    get rejected() {
        return this.request.rejected_time;
    }

    get recurring() {
        return this.request.frequency !== null;
    }

    get writable() {
        return [
            ASSIGNMENT_STATUS.PROGRESS,
            ASSIGNMENT_STATUS.EDITORIAL
        ].includes(this.request.latest_assignment?.status ?? 0);
    }

    get editorial() {
        return [ASSIGNMENT_STATUS.EDITORIAL].includes(
            this.request.latest_assignment?.status ?? 0
        );
    }

    get sent() {
        return [ASSIGNMENT_STATUS.SENT].includes(
            this.request.latest_assignment?.status ?? 0
        );
    }

    get flagged() {
        return [ASSIGNMENT_STATUS.FLAGGED].includes(
            this.request.latest_assignment?.status ?? 0
        );
    }

    get hasAmp() {
        return this.request.latest_assignment?.announcement_id ?? false;
    }

    get assignedToSelf() {
        return (
            this.request.latest_assignment?.is_assigned_to_current_user ?? false
        );
    }

    get assigned() {
        return this.request.latest_assignment?.author_id ?? 0;
    }

    get actionNeeded() {
        return this.request.status_string === 'Action Needed';
    }

    getActions() {
        return Array.from(this.getUniqueActions()).filter(Boolean);
    }

    getBaseActions() {
        if (this.paused) {
            return new Set<RequestAction>([
                actions.review,
                actions.settings,
                actions.comments
            ]);
        }

        if (this.editable) {
            return new Set<RequestAction>([actions.edit, actions.remove]);
        }

        const baseActions = new Set([actions.comments, actions.review]);

        if (this.actionNeeded) {
            baseActions.add(actions.edit_article);
        }

        if (this.recurring && !this.rejected) {
            baseActions.add(actions.settings);
        }

        return baseActions;
    }

    getUniqueActions() {
        if (!(this.isAuthor || this.isEditor)) {
            return this.getBaseActions();
        }

        const availableActions = new Set([actions.review]);

        if (this.recurring && this.isEditor && !this.rejected) {
            availableActions.add(actions.settings);
        }

        return availableActions;
    }

    hasAction(action: string) {
        const found = this.getActions().find(
            available => available.action === action
        );
        return !!found;
    }
}
