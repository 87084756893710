<template>
    <v-card
        v-if="loading"
        class="amp-card amp-card--top-border unapproved pt-3 pl-2"
        style="padding-bottom: 1px"
    >
        <v-skeleton-loader
            type="heading"
            style="margin-top: -2px"
            :width="randomBetween(25, 55) + '%'"
        />
        <v-list-item three-line class="pa-0 pl-2">
            <v-avatar rounded size="100" color="main-background">
                <v-icon color="grey lighten-2" large>image</v-icon>
            </v-avatar>
            <v-list-item-content class="mt-2">
                <v-skeleton-loader
                    class="pl-4"
                    type="heading"
                    :width="randomBetween(5, 15) + '%'"
                />
                <v-skeleton-loader
                    type="list-item-two-line"
                    :width="randomBetween(35, 55) + '%'"
                />
            </v-list-item-content>
        </v-list-item>
    </v-card>
    <v-card
        v-else
        class="amp-card amp-card--top-border"
        :class="rootClass"
        @[actionBinder]="onAction"
    >
        <div class="status-bar mt-2 ml-2">
            <status-chip :status="status" />

            <status-chip
                v-if="isOverdue"
                :tooltip="overdueTooltip"
                color="white"
                icon-only
            >
                <template #icon>
                    <v-icon color="error" small>far fa-clock</v-icon>
                </template>
            </status-chip>

            <status-chip
                v-if="isFirstRecurring"
                status="First Recurring Request"
                class="mr-1 font-weight-black text-button"
                label="1"
            />

            <status-chip
                v-if="isLimitedDistribution"
                status="Selective Distribution"
            />

            <status-chip v-if="isPremium" status="Premium" />

            <status-chip v-if="isFinancial" status="Financial" />

            <status-chip v-if="isMsn" status="MSN" />
        </div>

        <v-list-item three-line>
            <author-request-media
                :request="request"
                :size="100"
                secondary
                class="ma-4 ml-0"
            />

            <v-list-item-content class="mt-2">
                <v-list-item-subtitle
                    v-if="category"
                    data-test="category_name"
                    class="align-self-start"
                >
                    {{ category }}
                </v-list-item-subtitle>

                <v-list-item-title
                    v-if="companyName"
                    class="align-self-start headline text-body-1 font-weight-bold"
                    data-test="company_name"
                >
                    <text-truncated :text="companyName" />
                </v-list-item-title>

                <v-list-item-subtitle
                    class="align-self-end text-right amp-card--date mt-2"
                    data-test="status_and_date"
                >
                    {{ statusAndDate }}
                </v-list-item-subtitle>

                <v-list-item-subtitle
                    v-if="isRecurring && !isPaused"
                    class="align-self-end text-right amp-card--date mt-2"
                >
                    {{ recurringTooltip }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import {
    snakeCase,
    formatDate,
    formatDateTime,
    isTextSelectionEvent,
    randomBetween
} from '@/utils/helpers';

import { StatusChip } from '@/components/StatusChip';
import { TextTruncated } from '@/components/TextTruncated';
import { AuthorRequestMedia } from '@/components/Authoring/AuthorRequestMedia';

import type { AuthorRequest } from '@/types/AuthorRequest';
import type { RequestAction } from '@/components/Authoring/AuthorRequestActions/AuthorRequestActionsFactory';

const AuthorRequestCardProps = Vue.extend({
    props: {
        request: {
            type: Object as PropType<AuthorRequest>,
            default(): AuthorRequest | null {
                return null;
            }
        },
        action: {
            type: Object as PropType<RequestAction | null>,
            default(): RequestAction | null {
                return null;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: { StatusChip, TextTruncated, AuthorRequestMedia },
    computed: { ...mapGetters('user', ['isAuthor', 'isEditor']) }
})
export default class AuthorRequestCard extends AuthorRequestCardProps {
    isAuthor!: boolean;
    isEditor!: boolean;

    get status() {
        return this.request?.status_string || '';
    }

    get rootClass() {
        return [snakeCase(this.status), 'd-flex', 'flex-column', 'flex-grow-1']
            .filter(Boolean)
            .join(' ');
    }

    get isOverdue() {
        return (
            (this.isAuthor || this.isEditor) &&
            this.request?.latest_assignment?.overdue
        );
    }

    get overdueTooltip() {
        return this.request?.latest_assignment?.created
            ? `The latest assignment for this amp is overdue. Created on ${formatDate(
                  this.request.latest_assignment.created
              )}`
            : '';
    }

    get isFirstRecurring() {
        const requestCount = this.request.author_request_assignments?.length;

        return requestCount === 1 && this.request.frequency !== null;
    }

    get isLimitedDistribution() {
        return (
            this.request?.latest_assignment?.limited_distribution_only ?? false
        );
    }

    get isPremium() {
        return (
            this.request.latest_assignment?.announcement?.is_premium ?? false
        );
    }

    get isFinancial() {
        return (
            this.request.latest_assignment?.announcement?.is_financial ?? false
        );
    }

    get isMsn() {
        return this.request.latest_assignment?.announcement?.is_msn ?? false;
    }

    get category() {
        return this.request?.category?.name || '';
    }

    get companyName() {
        return this.request?.company?.name;
    }

    get isRecurring() {
        return (
            this.request?.requested_time &&
            !this.request?.rejected_time &&
            this.request.frequency !== null
        );
    }

    get recurringTooltip() {
        return `Recurring Request - Next Amp on ${this.nextAmpAt}`;
    }

    get nextAmpAt() {
        if (
            this.request?.latest_assignment &&
            this.request?.requested_time &&
            this.request?.frequency
        ) {
            if (
                this.request.latest_assignment.created >
                this.request.requested_time
            ) {
                return this.nextAmpAtDate(
                    this.request.latest_assignment.created,
                    this.request.frequency
                );
            }

            return this.nextAmpAtDate(
                this.request.requested_time,
                this.request.frequency
            );
        }

        return '';
    }

    get statusAndDate() {
        if (this.request?.rejected_time) {
            return `Rejected ${formatDate(this.request.rejected_time)}`;
        }

        if (
            this.isAuthor &&
            this.request?.latest_assignment?.announcement?.press_release
        ) {
            return `Last Edited ${formatDate(
                this.request.latest_assignment.announcement.press_release
                    .modified
            )}`;
        }

        if (this.request?.requested_time) {
            return `Submitted ${formatDate(this.request.requested_time)}`;
        }

        return this.request?.created
            ? `Requested ${formatDate(this.request.created)}`
            : '';
    }

    get hasAction() {
        return Boolean(this.action);
    }

    get actionBinder() {
        return this.hasAction ? 'click' : 'nonce';
    }

    get isPaused() {
        return this.request.paused_at !== null;
    }

    get activeAction() {
        return this.hasAction ? this.onAction : null;
    }

    onAction(e: Event) {
        if (this.hasAction && !isTextSelectionEvent(e)) {
            const result = this.execute();

            if (result) {
                if (this.action?.link) {
                    window.location.href = result;
                }

                if (this.action?.route) {
                    this.$router.push(result);
                }
            }
        }
    }

    execute() {
        return this.action?.callback(this, this.request);
    }

    nextAmpAtDate(date: number, frequency: number) {
        return formatDateTime(
            this.$date.unix(date).add(frequency, 'seconds').unix()
        );
    }

    formatDate = formatDate;
    randomBetween = randomBetween;
}
</script>

<style lang="scss" scoped>
@import '@/components/ACard/ACard.scss';
</style>
